<template>
  <v-container>
    <h2 class="primary--text">{{ title }}</h2>
    <v-row no-gutters justify="space-between">
      <v-checkbox v-model="search.all" label="Voir toutes les factures"></v-checkbox>
      <v-card color="primary" class="ma-4 pa-3 text-center">
        <h2 class="white--text">{{total_factures.toFixed(2)}} € <br> de {{ title.toLowerCase() }}</h2>
      </v-card>
      <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
      <v-btn @click="resetFilters" color="red" class="white--text">
        <v-icon>mdi-trash-can</v-icon>
        Effacer les filtres
      </v-btn>

    </v-row>
    <v-row no-gutters justify="end">
      <v-btn @click="exportPdf" v-if="title === 'Factures payées'" :disabled="exporting" color="primary"
             class="white--text">
        Exporter les justificatifs de la sélection
      </v-btn>
    </v-row>
    <v-simple-table height="70vh" class="mt-4" fixed-header dense>
      <template>
        <thead>
        <tr>
          <th>Nom du bénéficiaire</th>
          <th>Enveloppe</th>
          <th>Entreprise</th>
          <th>
            <v-row justify="space-between" no-gutters>
              <span>Date de règlement</span>
              <v-icon color="primary" v-if="sort.date_reglement === 0" @click="sort.date_reglement = 1">mdi-sort
              </v-icon>
              <v-icon color="primary" v-if="sort.date_reglement === 1" @click="sort.date_reglement = -1">
                mdi-sort-ascending
              </v-icon>
              <v-icon color="primary" v-if="sort.date_reglement === -1" @click="sort.date_reglement = 0">
                mdi-sort-descending
              </v-icon>
            </v-row>
          </th>
          <th>
            <v-row justify="space-between" no-gutters>
              <span>Date de facture</span>
              <v-icon color="primary" v-if="sort.date_facture === 0" @click="sort.date_facture = 1">mdi-sort</v-icon>
              <v-icon color="primary" v-if="sort.date_facture === 1" @click="sort.date_facture = -1">
                mdi-sort-ascending
              </v-icon>
              <v-icon color="primary" v-if="sort.date_facture === -1" @click="sort.date_facture = 0">
                mdi-sort-descending
              </v-icon>
            </v-row>
          </th>
          <th>Référence de la facture</th>
          <th>Montant</th>
          <th>Statut</th>
          <th>Facture</th>
          <th>Actions</th>
        </tr>
        <tr></tr>
        <tr>
          <th>
            <v-text-field dense label="Nom du bénéficiaire" v-model="search.name"></v-text-field>
          </th>
          <th>
            <v-select dense :items="types_decaissement" v-model="search.source"></v-select>
          </th>
          <th>
            <v-select dense :items="entreprises_select" item-text="name" item-value="id"
                      v-model="search.entreprise"></v-select>
          </th>
          <th>
            <v-select dense :items="months_date_reglement" item-text="name" item-value="value"
                      v-model="search.date_reglement"></v-select>
          </th>
          <th>
            <v-select dense :items="months_date_facture" item-text="name" item-value="value"
                      v-model="search.date_facture"></v-select>
          </th>
          <th></th>
          <th></th>
          <th>
            <v-select dense :items="statut_select" item-text="name" item-value="value"
                      v-model="search.statut"></v-select>
          </th>
          <th>
            <v-select style="max-width: 150px" dense :items="justificatifSelect" v-model="search.justificatif"
                      item-value="value" item-text="name"></v-select>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="reglement in filtered_reglements" :key="reglement.id">
          <td>
            <router-link class="text-decoration-none" :to="'/finances/' + reglement.dossier_id">
              {{ reglement.beneficiaire_name }}
            </router-link>
          </td>
          <td>{{ reglement.saisie_source }}</td>
          <td>
            <router-link class="text-decoration-none" :to="'/entreprises/' + reglement.entreprise">
              {{ reglement.entreprise_name }}
            </router-link>
          </td>
          <td>{{ getFormattedDate(reglement.date_reglement) }}</td>
          <td>{{ getFormattedDate(reglement.date_facture) }}</td>
          <td>{{ reglement.reference_facture }}</td>
          <td>{{ reglement.montant }} €</td>
          <td>
            <reglement-status-chip :reglement="reglement"></reglement-status-chip>
          </td>
          <td><span v-if="reglement.file"><a :href="baseUrl + reglement.file" target="_blank"><v-icon color="primary">mdi-attachment</v-icon></a></span>
          </td>
          <td>
            <DownloadReglementFile @reload="reloadReglements" :reglement="reglement"/>
            <switch-reglement-status-menu
                v-if="(reglement.status === 'waiting' && isAdmin) || (reglement.status === 'check' && hasFinanceRole)"
                @reload="reloadReglements"
                saisie_type="decaissement"
                :reglement="reglement"></switch-reglement-status-menu>
            <AddFileReglement v-if="reglement.status === 'waiting'" @reload="reloadReglements" :reglement="reglement"
                              saisie_type="decaissement"/>
            <RefuseReglement :reglement="reglement" v-if="reglement.status === 'waiting'" @reload="reloadReglements"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </v-container>
</template>

<script>
import {ReglementRepository} from "@/repository/reglement";
import ReglementStatusChip from "@/components/finances/ReglementStatusChip.vue";
import {TYPES_DECAISSEMENT} from "@/constants/Financement";
import {EntrepriseRepository} from "@/repository/entreprises";
import SwitchReglementStatusMenu from "@/views/SwitchReglementStatusMenu.vue";
import AddFileReglement from "@/views/AddFileReglement.vue";
import * as config from '@/config.json';
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import DownloadReglementFile from "@/views/DownloadReglementFile.vue";
import RefuseReglement from "@/views/RefuseReglement.vue";
import {AuthMixin} from "@/mixins/AuthMixin";
import dayjs from "dayjs";

export default {
  name: "Factures",
  components: {
    RefuseReglement,
    DownloadReglementFile,
    AddFileReglement,
    SwitchReglementStatusMenu,
    ReglementStatusChip
  },
  mixins: [DateUtilMixin, AuthMixin],
  async mounted() {
    this.loading = true;
    let statut = this.$route.params?.statut;
    if (statut === "waiting") {
      this.search.statut = "waiting";
      this.title = "Factures en attente";
    } else if (statut === "valid") {
      this.search.statut = "valid";
      this.title = "Factures à payer";
    } else if (statut === "check") {
      this.search.statut = "check";
      this.title = "Virements à vérifier";
    } else if (statut === "paid") {
      this.search.statut = "paid";
      this.title = "Factures payées";
    } else {
      this.search.statut = "waiting";
      this.title = "Factures en attente";
    }
    let repository = new ReglementRepository();
    this.reglements = await repository.fetchFactures();
    let entrepriseRepo = new EntrepriseRepository();
    this.entreprises = await entrepriseRepo.fetch();
    this.loading = false;
  },
  data() {
    return {
      reglements: [],
      search: {
        name: "",
        source: "Tout",
        statut: null,
        entreprise: null,
        justificatif: null,
        all: false,
        date_reglement: null,
        date_facture: null,
      },
      sort: {
        date_reglement: 0,
        date_facture: 0,
      },
      exporting: false,
      title: "",
      loading: false,
      types_decaissement: ["Tout", ...TYPES_DECAISSEMENT],
      entreprises: [],
      justificatifSelect: [
        {name: "Tous", value: null},
        {name: "Avec facture", value: "avec"},
        {name: "Sans facture", value: "sans"},
      ],
      baseUrl: config.BASE_URL,
      downloading: false,
    }
  },
  computed: {
    tableHeight() {
      return window.innerHeight * 0.7;
    },
    entreprises_select() {
      return [{id: null, name: "Toutes les entreprises"}, ...this.entreprises]
    },
    statut_select() {
      return [
        {value: null, name: "Toutes les factures"},
        {value: "waiting", name: "En attente"},
        {value: "valid", name: "Validé"},
        {value: "check", name: "A vérifier"},
        {value: "paid", name: "Payé"},
      ];
    },
    filtered_reglements: function () {
      return this.reglements
          .filter((reglement) => this.search.name !== "" ? reglement.beneficiaire_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.search.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) : true)
          .filter((reglement) => this.search.source !== "Tout" ? reglement.saisie_source === this.search.source : true)
          .filter((reglement) => this.search.entreprise !== null ? reglement.entreprise === this.search.entreprise : true)
          .filter((reglement) => this.search.justificatif !== null ? this.search.justificatif === "avec" ? reglement.file !== null : reglement.file === null : true)
          .filter((reglement) => this.search.all ? true : this.search.statut !== null ? this.search.statut === reglement.status : true)
          .filter((reglement) => this.search.date_reglement === "" ? reglement.date_reglement === null : this.search.date_reglement !== null ? this.isSameMonth(this.search.date_reglement, reglement.date_reglement) : true)
          .filter((reglement) => this.search.date_reglement === "" ? reglement.date_facture === null : this.search.date_facture !== null ? this.isSameMonth(this.search.date_facture, reglement.date_facture) : true)
          .sort((reglementA, reglementB) => {
            if (this.sort.date_reglement !== 0 && reglementA.date_reglement && reglementB.date_reglement) {
              if (this.sort.date_reglement === 1) {
                return dayjs(reglementA.date_reglement).isAfter(reglementB.date_reglement) ? 1 : -1;
              } else {
                return dayjs(reglementB.date_reglement).isAfter(reglementA.date_reglement) ? 1 : -1;
              }
            } else {
              return 0;
            }
          }).sort((reglementA, reglementB) => {
            if (this.sort.date_facture !== 0 && reglementA.date_facture !== null && reglementB.date_facture !== null) {
              if (this.sort.date_facture === 1) {
                return dayjs(reglementA.date_facture).isAfter(reglementB.date_facture) ? 1 : -1;
              } else {
                return dayjs(reglementB.date_facture).isAfter(reglementA.date_facture) ? 1 : -1;
              }
            } else {
              return 0;
            }
          });
    },
    total_factures() {
      return this.filtered_reglements.reduce((acc, reglement) => acc + parseFloat(reglement.montant), 0);
    },
    months_date_reglement() {
      let reglements = this.reglements.filter(reglement => reglement.date_reglement !== null);
      var minDate = reglements.reduce((min, reglement) => {
        var currentDate = new Date(reglement.date_reglement);
        return currentDate < min ? currentDate : min;
      }, new Date());

      var maxDate = reglements.reduce((max, reglement) => {
        var currentDate = new Date(reglement.date_reglement);
        return currentDate > max ? currentDate : max;
      }, new Date(0));
      minDate = new Date(minDate);
      maxDate = new Date(maxDate);
      var months = [];
      var currentDate = new Date(minDate);
      while (currentDate <= maxDate) {
        var monthYear = currentDate.toLocaleString("default", {month: "long", year: "numeric"});
        months.push({
          name: monthYear,
          value: currentDate.toDateString(),
        });
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      return [{name: "Tout les mois", value: null}, {name: "Non saisie", value: ""}, ...months];
    },
    months_date_facture() {
      let reglements = this.reglements.filter(reglement => reglement.date_facture !== null);
      var minDate = reglements.reduce((min, reglement) => {
        var currentDate = new Date(reglement.date_facture);
        return currentDate < min ? currentDate : min;
      }, new Date());

      var maxDate = reglements.reduce((max, reglement) => {
        var currentDate = new Date(reglement.date_facture);
        return currentDate > max ? currentDate : max;
      }, new Date(0));
      minDate = new Date(minDate);
      maxDate = new Date(maxDate);
      var months = [];
      var currentDate = new Date(minDate);
      while (currentDate <= maxDate) {
        var monthYear = currentDate.toLocaleString("default", {month: "long", year: "numeric"});
        months.push({
          name: monthYear,
          value: currentDate.toDateString(),
        });
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      return [{name: "Tout les mois", value: null}, {name: "Non saisie", value: ""}, ...months];
    }

  },
  methods: {
    async reloadReglements() {
      let repository = new ReglementRepository();
      this.reglements = await repository.fetchFactures();
    },
    async exportPdf() {
      for (let reglement of this.filtered_reglements) {
        if (reglement.file === null) {
          await this.$store.dispatch("alerts/announceWarning", {
                "title": "Impossible d'effectuer cette action",
                "message": "Impossible d'exporter la sélection : Certaines factures n'ont pas de justificatifs."
              }
          );
          return;
        }
      }
      this.exporting = true;
      let factures = this.filtered_reglements.map(reglement => reglement.id);
      await this.$store.dispatch("dossier/createExport", {
        id: 0,
        factures: factures,
        type: "normal"
      })
      this.exporting = false;
    },
    resetFilters() {
      this.search = {
        name: "",
        source: "Tout",
        entreprise: null,
        justificatif: null,
        all: false,
      };
    }
  }

}
</script>

